<template>
  <div class="auth-streamer-demo">
    <ElButton
      class="auth-streamer-demo__button"
      type="primary"
      @click="onClick"
    >
      Try Demo Account
    </ElButton>
  </div>
</template>

<script setup>
import ElButton from 'element/ElButton'
import { Api } from 'lib/api/instance'
import { setDemoToken } from 'lib/helpers/authToken'

const onClick = async () => {
  const res = await Api.get('auth/streamer/demo')
  const url = `${window.location.origin}?access_token=${res.access_token}`
  setDemoToken()
  window.location = url
}
</script>

<style lang="scss">
.auth-streamer-demo {
  border-top: 1px solid $--color-lightest-gray;
  margin-top: 24px;
  padding-top: 24px;

  &__title {
    margin-bottom: 16px;
  }

  &__button {
    width: 100%;
  }
}
</style>
